import { chakra, VisuallyHidden, useColorModeValue, Box, Container, Stack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaInstagram, FaFacebook, FaLinkedin, FaTiktok } from 'react-icons/fa';

const SocialButton = ({
  children,
  label,
  href
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      // bg={useColorModeValue('purple.800', 'gray.800')}
      color={useColorModeValue('purple.600', 'purple.200')}
      rounded={'full'}
      w={12}
      h={12}
      cursor={'pointer'}
      as={'a'}
      target="blank"
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        // bg: useColorModeValue('purple.600', 'gray.700'),
        boxShadow: '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const iconSize: number = 32;
  return (
    <Box
      color={useColorModeValue('gray.600', 'white')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Text fontSize={'sm'}>© 2022 Anaïs Oury. All rights reserved</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Facebook'} href={'https://www.facebook.com/ANAISOURYMUA'}>
            <FaFacebook size={iconSize} />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/anais.oury/'}>
            <FaInstagram size={iconSize} />
          </SocialButton>
          <SocialButton label={'Linkedin'} href={'https://www.linkedin.com/in/ana%C3%AFs-oury-a3b439259/'}>
            <FaLinkedin size={iconSize} />
          </SocialButton>
          <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@anais.oury'}>
            <FaTiktok size={iconSize} />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  )
}