import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  CardFooter,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import customMakeup from "../../../assets/custom-makeup.jpeg";

export default function CustomMakeup() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "sm", lg: "sm" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <VStack>
          <Heading
            fontSize={headingSize}
            variant='anais'
          >
            Maquillages personnalisés
          </Heading>
          <Text as="i">(disponible en carte cadeau à offrir)</Text>
        </VStack>
        <Image
          src={customMakeup}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>
          Je réalise, selon votre souhait, une mise en beauté allant du plus
          naturel au pus sophistiqué en m'adaptant à l'occasion : une soirée, un
          shooting photo ou pour le plaisir.
        </Text>
        <Heading fontSize="2xl" variant='anais' textDecor="underline">
          Services
        </Heading>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text whiteSpace="pre-line" h={{ md: 24, lg: 24 }}>
                    {cardItem.text}
                  </Text>
                </CardBody>
                <CardFooter>
                  <Text as="i">{cardItem.footer}</Text>
                </CardFooter>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
  footer: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Mise en beauté naturelle",
    text: "Le maquillage c'est l'art de révéler la beauté sans masquer le naturel.\nMaquillage du teint, des yeux et de la bouche pour un résultat naturel, frais et lumineux.",
    footer: "Carte cadeau à offrir",
  },
  {
    header: "Mise en beauté sophistiquée",
    text: "Vous êtes conviée à une soirée ou un cocktail ? Contouring, intensité des yeux, faux-cils et paillettes seront au rendez-vous selon vos envies.",
    footer: "Carte cadeau à offrir",
  },
  {
    header: "Maquillage Halloween",
    text: "Etude de votre demande et échanges autour du projet. Du squelette aux cicatrices, vous allez être terrifiant !",
    footer: "",
  },
  {
    header: "Maquillages originaux / festifs",
    text: "Paillettes, strass, fards multichromes. Embarquement immédiat pour Coachella.",
    footer: "",
  },
];
