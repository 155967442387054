import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import audiovisual from "../../../assets/audiovisual.jpeg";

export default function AudioVisual() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "lg", lg: "xl" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <Heading
          fontSize={headingSize}
          variant='anais'
        >
          Audiovisuel
        </Heading>
        <Image
          src={audiovisual}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>
          Qu'il s'agisse de tournage pour une émission, une publicité, ou un clip
          vidéo, je maquille les acteurs, présentateurs et artistes pour les
          mettre en valeur, du maquillage le plus naturel passant par la beauté
          puis par les effets spéciaux.
        </Text>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text h={{ md: 16, lg: 16 }}>{cardItem.text}</Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "No make up look",
    text: "Réalisation d'un maquillage effet peau nue, pour les hommes par exemple, visant à un résultat homogène devant les caméras.",
  },
  {
    header: "Mise en beauté",
    text: "Maquillage classique à sophistiqué, adaptés aux tournages professionnels.",
  },
  {
    header: "FX (niv. 1)",
    text: "Création de cicatrices, de brûlures, d'hématomes, de prothèses, de vieillissement, ...",
  },
  {
    header: "Théatre",
    text: "Maquillage des artistes sur le thème de la pièce interprêtée.",
  },
];
