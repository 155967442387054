import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Heading,
  VStack,
  InputGroup,
  InputLeftElement,
  Textarea,
  Button,
  Stack,
  HStack,
  Text,
  Box,
  Image,
  useColorModeValue,
  useBreakpointValue,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";

import { MdPerson, MdEmail, MdPhone, MdMail } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";

import shouby from "../../assets/anais-small.jpg";

type Inputs = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

export default function Contact() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const sectionSpacing = useBreakpointValue({ base: 0, md: 16, lg: 32 });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const toast = useToast();

  const REQUIRED_MESSAGE = "Ce champ est obligatoire";

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    let response = await fetch("/api/send", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        phone: data.phone,
        subject: "Nouveau message de : " + data.name,
        message: data.message,
      }),
    });
    let toastProps;
    if (response.ok) {
      toastProps = {
        title: "Succès",
        description: "L'email a bien été envoyé.",
        status: "success" as UseToastOptions["status"],
      };
      reset();
    } else {
      console.log("response", response);
      toastProps = {
        title: "Erreur",
        description: "l'email n'a pas pu être délivré.",
        status: "error" as UseToastOptions["status"],
      };
    }
    toast({
      ...toastProps,
      isClosable: true,
    });
  };
  return (
    <VStack spacing={{ base: 1, md: 10 }}>
      <Heading fontSize={headingSize} variant='anais'>
        Contactez-moi
      </Heading>
      <Stack spacing={sectionSpacing} direction={["column", "column", "row"]}>
        <ContactCard />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4} w={{ base: "100%", md: "400px" }}>
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel>Nom / Prénom</FormLabel>
              <InputGroup>
                <InputLeftElement children={<MdPerson />} />
                <Input
                  variant="filled"
                  id="name"
                  {...register("name", {
                    required: REQUIRED_MESSAGE,
                    minLength: {
                      value: 4,
                      message: "La longueur minimum est de 4 caractères.",
                    },
                  })}
                />
              </InputGroup>
              {errors.name && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={Boolean(errors.email)}>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement children={<MdEmail />} />
                <Input
                  variant="filled"
                  id="email"
                  {...register("email", {
                    required: REQUIRED_MESSAGE,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email non valide.",
                    },
                  })}
                />
              </InputGroup>
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={Boolean(errors.phone)}>
              <FormLabel>Téléphone</FormLabel>
              <InputGroup>
                <InputLeftElement children={<MdPhone />} />
                <Input
                  variant="filled"
                  id="phone"
                  {...register("phone", {
                    required: REQUIRED_MESSAGE,
                    pattern: {
                      value:
                        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
                      message: "Numéro de téléphone non valide.",
                    },
                  })}
                />
              </InputGroup>
              {errors.phone && (
                <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={Boolean(errors.message)}>
              <FormLabel>Message</FormLabel>
              <Textarea
                variant="filled"
                rows={12}
                resize="none"
                placeholder="Entrez votre message..."
                id="message"
                {...register("message", {
                  required: REQUIRED_MESSAGE,
                  minLength: {
                    value: 10,
                    message: "La longueur minimum est de 10 caractères.",
                  },
                })}
              />
              {errors.message && (
                <FormErrorMessage>{errors.message.message}</FormErrorMessage>
              )}
            </FormControl>

            <Button
              w="full"
              colorScheme="purple"
              type="submit"
              isLoading={isSubmitting}
            >
              Envoyer
            </Button>
          </VStack>
        </form>
      </Stack>
    </VStack>
  );
}

const ContactCard = () => {
  return (
    <Center py={12}>
      <Box
        role={"group"}
        p={6}
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        <Box
          rounded={"lg"}
          mt={-12}
          pos={"relative"}
          height={"230px"}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "full",
            h: "full",
            pos: "absolute",
            top: 5,
            left: 0,
            backgroundImage: `url(${shouby})`,
            filter: "blur(15px)",
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: "blur(20px)",
            },
          }}
        >
          <Image
            rounded={"lg"}
            height={230}
            width={282}
            objectFit={"cover"}
            src={shouby}
          />
        </Box>
        <Stack pt={10} align={"center"}>
          <HStack>
            <MdPhone />
            <Text fontSize="xl">+33 6 38 91 73 93</Text>
          </HStack>
          <HStack>
            <MdMail />
            <Text fontSize="xl">anaisoury@hotmail.com</Text>
          </HStack>
        </Stack>
      </Box>
    </Center>
  );
};
