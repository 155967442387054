import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  listAll,
  getMetadata,
  getDownloadURL,
  StorageReference,
} from "firebase/storage";
import { BookCardInfo } from "../interfaces/BookCardInfo";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "anais-oury.firebaseapp.com",
  projectId: "anais-oury",
  storageBucket: "anais-oury.appspot.com",
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

function getFolderRef(folderName: string): StorageReference {
  return ref(storage, folderName);
}

function getBookInfo(itemRef: StorageReference): Promise<BookCardInfo> {
  return getMetadata(itemRef).then((metadata) =>
    getDownloadURL(itemRef).then((url) => ({ name: metadata.name, url: url }))
  );
}

function getAllBookCardInfo(folderName: string): Promise<BookCardInfo[]> {
  console.log(folderName)
  const folderRef = getFolderRef(folderName);
  return listAll(folderRef)
    .then((res) => Promise.all(res.items.map(getBookInfo)))
    .catch((error) => {
      console.log(error);
      throw error; // Renvoyer l'erreur pour la gérer plus tard si nécessaire
    });
}

function listAllFolders(): Promise<string[]> {
  return listAll(getFolderRef(""))
    .then((res) => res.prefixes.map((prefix) => prefix.name.trim()))
    .catch((error) => {
      console.log(error);
      throw error; // Renvoyer l'erreur pour la gérer plus tard si nécessaire
    });
}

export { getAllBookCardInfo, listAllFolders };
