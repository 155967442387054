import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import makeupCourses from "../../../assets/makeup-courses.jpeg";

export default function MakeupCourses() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "lg", lg: "xl" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <VStack>
          <Heading
            fontSize={headingSize}
            variant='anais'
          >
            Cours d'auto-maquillage
          </Heading>
          <Text as="i">(disponible en carte cadeau à offrir)</Text>
        </VStack>
        <Image
          src={makeupCourses}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>En solo, en duo ou à plusieurs</Text>
        <Text fontSize={textSize}>
          Vous souhaitez apprendre les techniques de maquillage correspondants à
          votre morphologie et votre style, optez pour un cours
          d'auto-maquillage. <br /> Je me déplace à votre domicile pour partager
          ensemble un moment convivial et riche en apprentissage.
        </Text>
        <Heading fontSize="2xl" variant='anais' textDecor="underline">
          Services
        </Heading>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text whiteSpace="pre-line">{cardItem.text}</Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Analyse de votre demande",
    text: 'Vous souhaitez acquérir les techniques concernant un maquillage de tous les jours, pour une soirée particulière incluant un focus teint/yeaux ou lèvres, précision des "pdts" à avoir pour le jour du cours.',
  },
  {
    header: "Examen de votre kit de maquillage",
    text: "Conseils sur votre matériel et sur les produits recommandés en fonction de votre type de peau.",
  },
  {
    header: "Pratique",
    text: "Mise à disposition de mon matériel.\nÉtape par étape, je procède au maquillage sur une partie de votre visage en détaillant chacun de mes gestes puis vous reproduisez sous mon oeil expert.\nÀ plusieurs : explications orales au cas par cas, accompagnées d'un guide offert. Démonstration si nécessaire.",
  },
  {
    header: "Conseils et astuces professionnels",
    text: "Adaptés à votre morphologie, vos besoins, vos attentes et votre type de peau.",
  },
  {
    header: "Guide de maquillage pour ne pas s'emmêler les pinceaux",
    text: "Guide de maquillage offert pour reprendre le matériel nécessaire et les astuces vues pendant le cours.",
  },
];
