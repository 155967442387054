import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: true,
}

const headingStyles = {
  variants: {
    'anais': {
      fontFamily:'Noto Sans',
      fontWeight: '700',
    }
  },
}

// 3. extend the theme
const theme = extendTheme({ 
  config: config, 
  components: {
    Heading: headingStyles
}
})

export default theme