import {
  Center,
  VStack,
  Image,
  Heading,
  Text,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import experience from "../../assets/anais-small.jpg";
import parcours from "../../assets/parcours.jpeg";

export default function About() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "lg", md: "lg", lg: "lg" });
  const sectionSpacing = useBreakpointValue({ base: 3, md: 4, lg: 32 });
  const imagesSize = useBreakpointValue({ base: 60, md: 64, lg: 80 });

  return (
    <Center>
      <VStack spacing={[5, 5, 10]}>
        <Heading fontSize={headingSize} variant='anais'>
          Expériences
        </Heading>
        <Stack spacing={sectionSpacing} direction={["column", "column", "row"]}>
          <Center>
            <Image
              boxShadow="dark-lg"
              src={experience}
              w={imagesSize}
              borderRadius={20}
            />
          </Center>
          <VStack maxW={"500px"} justify="center" textAlign="justify">
            <Text fontSize={textSize}>
              Maquilleuse professionnelle en freelance à temps plein, je vis mon
              rêve au quotidien.
            </Text>
            <Text fontSize={textSize}>
              Passionnée par le maquillage, j'ai trouvé en lui mon art. Cette
              manière de s'exprimer à travers formes, textures et couleurs,
              offre une multitude de possibilités pour atteindre le naturel, le
              chic, l'original et même l'extravagant.
            </Text>
            <Text fontSize={textSize}>
              Je mets mes connaissances, mes compétences et ma passion au
              service des particuliers et des professionnels pour tous types de
              projets.
            </Text>
            <Text fontSize={textSize}>
              Mon métier m'offre la chance d'exercer dans différents univers
              tels que la beauté, la mode, l'audiovisuel, le théatre et
              l'artistique.
            </Text>
          </VStack>
        </Stack>
        <Heading fontSize={headingSize} variant='anais'>
          Parcours
        </Heading>
        <Stack
          spacing={sectionSpacing}
          direction={["column-reverse", "column-reverse", "row"]}
        >
          <VStack maxW={"500px"} justify="center" textAlign="justify">
            <Text fontSize={textSize}>
              Titulaire d'un CAP, BAC et BTS esthétique cosmétique, parfumerie,
              il était évident pour moi de clôturer mes études par une formation
              en maquillage professionnel.
            </Text>
            <Text fontSize={textSize}>
              Pour cela, j'ai souhaité partir à l'aventure en intégrant la
              préstigieuse école Make Up For Ever à Nice où j'ai effectué une
              formation complète.
            </Text>
            <Text fontSize={textSize}>
              Pendant cette formidable année, j'ai acquis de solides
              connaissances et techniques tout en développant ma créativité.
            </Text>
            <Text fontSize={textSize} as="p">
              À l'issue de cette formation, officiellement maquilleuse
              professionnelle, j'ai dans un premier temps travaillé pour des
              enseignes de marques de beauté, puis, ayant soif d'aventure et de
              challenge, je me suis lancée en freelance afin de vivre pleinement
              de mon rêve éveillé.
            </Text>
            <Text fontSize={textSize} as="p">
              De plus, je suis formée en Coiffure Studio par Make Up For Ever afin de
              réaliser vos coiffures pour certaines occasions telles que
              les shootings, les baptêmes, la mode ou l'éditorial.
            </Text>
          </VStack>
          <Center>
            <Image
              boxShadow="dark-lg"
              src={parcours}
              w={imagesSize}
              borderRadius={20}
            />
          </Center>
        </Stack>
      </VStack>
    </Center>
  );
}
