import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import childrenMakeup from "../../../assets/custom-makeup.jpeg";

export default function ChildrenMakeup() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "sm", lg: "sm" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <VStack>
          <Heading
            fontSize={headingSize}
            variant='anais'
          >
            Maquillage enfant
          </Heading>
          <Text as="i">(à partir de 3 ans)</Text>
        </VStack>
        <Image
          src={childrenMakeup}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>
          À l'occasion d'un anniversaire, d'un vin d'honneur, d'un baptême,
          d'une communion, d'une fête déguisée.
        </Text>
        <Text fontSize={textSize}>
          Avec du matériel professionnel, de qualité, hypoallergénique et
          lavable à l'eau et au savon.
        </Text>
        <Heading fontSize="2xl" variant='anais' textDecor="underline">
          Services
        </Heading>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(3, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text h={{ md: 24, lg: 24 }}>{cardItem.text}</Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Maquillage enfant",
    text: "Création de maquillages rapides aux fards à l'eau pour les petits.",
  },
  {
    header: "Face-painting",
    text: "Création d'un maquillage aux fards à l'eau pour une véritable métamorphose.",
  },
  {
    header: "Tatouages pailletés",
    text: "Session de tatouages éphemères pailletés pour les grands et les petits.",
  },
];
