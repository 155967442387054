import { useState, useRef, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const scrollButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (scrollButton.current) scrollButton.current.blur();
  };

  return (<IconButton
    icon={<ArrowUpIcon />}
    aria-label={"scroll to top"}
    variant={"outline"}
    border={"2px solid"}
    colorScheme="purple"
    visibility={isVisible ? "visible" : "hidden"}
    ref={scrollButton}
    onClick={handleClick}
    position="fixed"
    bottom="20px"
    right={["16px", "84px"]}
    zIndex={1}
  ></IconButton>);
}