import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import wedding from "../../../assets/wedding.jpeg";

export default function Wedding() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "sm", lg: "sm" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <Heading
          fontSize={headingSize}
          variant='anais'
        >
          Mariages
        </Heading>
        <Image src={wedding} boxShadow="dark-lg" borderRadius={imageWidth} />
        <Text fontSize={textSize}>
          Un évenement inoubliable tel que votre mariage doit être à la hauteur
          de vos attentes et vous correspondre. Je serai à votre écoute tout au
          long de la préparation pour vous conseiller et vous aiguiller lors de
          la mise en beauté, qu'elle soit naturelle ou sophistiquée.
        </Text>
        <Text fontSize={textSize}>
          Nous échangerons autour de vos envies, vos goûts et vos idées en amont
          et lors de notre rendez-vous pour réaliser l'essai maquillage que nous
          peaufinerons ensemble pour qu'il vous convienne à 100%.
        </Text>
        <Text fontSize={textSize}>
          C'est le jour J ! <br /> Je me tiens à votre disposition en accord
          avec votre organisation afin que ce jour exceptionnel soit le plus
          beau.
        </Text>
        <Text fontSize={textSize}></Text>
        <Heading fontSize="2xl" variant='anais' textDecor="underline">
          Services
        </Heading>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(4, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text>{cardItem.text}</Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Conseil",
    text: "Etude de votre demande, échanges autour de votre projet maquillage, propositions personnalisées.",
  },
  {
    header: "Essai Maquillage",
    text: "Rendez-vous pour la réalisation de l'essai maquillage, modifications selon vos envies.",
  },
  {
    header: "Jour J",
    text: "Réalisation du maquillage élaboré ensemble à votre domicile, chez le coiffeur ou au domaine souhaité.",
  },
  {
    header: "Maquillage invitée",
    text: "Réalisation de la mise en beauté de vos convives pour un petit moment appréciable entre filles.",
  },
];
