import {
  Center,
  Container as Box,
  Flex,
  Heading,
  Image,
  Stack,
  useBreakpointValue,
  VStack,
  Text,
} from "@chakra-ui/react";

import home from "../../assets/home.jpeg";

export default function Home() {
  const sectionSpacing = useBreakpointValue({ base: 5, md: 5, lg: 32 });
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const mainTextSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const mainTextPadding = useBreakpointValue({ base: 2, md: 2, lg: 5 });
  const imageWidth = useBreakpointValue({ base: "sm", md: "2xl", lg: "md" });
  return (
    <Flex justify="center">
      <VStack spacing={[2, 10]}>
        <Center>
          <Heading fontSize={headingSize} variant='anais'>
            Anaïs Oury
          </Heading>
        </Center>
        <Stack spacing={sectionSpacing} direction={["column", "column", "row"]}>
          <Center>
            <Image boxShadow="dark-lg" src={home} w={imageWidth} />
          </Center>
          <Box textAlign="justify">
            <Text fontSize={mainTextSize} p={mainTextPadding}>
              Maquilleuse professionnelle en free-lance depuis 2019, le
              maquillage était une évidence depuis mes premières années d’études
              en esthétique.
            </Text>
            <Text fontSize={mainTextSize} p={mainTextPadding}>
              Chez Make Up For Ever, j’ai pu acquérir des compétences techniques
              et artistiques.
            </Text>
            <Text fontSize={mainTextSize} p={mainTextPadding}>
              Passionnée plus que jamais, je mets mon savoir-faire à disposition
              dans différents domaines et toujours AVEC PASSION.
            </Text>
            <Text fontSize={mainTextSize} p={mainTextPadding}>
              Lorsque je maquille, je vis.
            </Text>
          </Box>
        </Stack>
      </VStack>
    </Flex>
  );
}
