import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import events from "../../../assets/events.jpeg";

export default function Events() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "xs", md: "xs", lg: "xs" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <Heading
          fontSize={headingSize}
          variant='anais'
        >
          Évènements
        </Heading>
        <Image
          src={events}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>
          Qu'il s'agisse d'un évènement privé ou d'entreprise, je vous propose
          mes services pour des maquillages sur le thème de votre choix.
        </Text>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(3, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais' h={{ md: 8, lg: 8 }}>
                    {cardItem.header}
                  </Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text h={{ md: 36, lg: 36 }}>{cardItem.text}</Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Maquillage enfant",
    text: "Atelier de maquillage à l'occasion d'une fête (arbre de Nöel, carnaval, ...) ou de spectacle.",
  },
  {
    header: "Maquillage artistique",
    text: "Création ou démonstration de maquillage artistique pour un évènement.",
  },
  {
    header: "Maquillages festifs / à thème",
    text: "Je m'adapte à votre projet en étudiant votre demande et en vous proposant des maquillages professionnels correspondants à vos souhaits.",
  },
];
