import { Container, Divider } from "@chakra-ui/react";

import { Outlet } from "react-router-dom";
import Footer from "../footer";

import Header from "../header";

export default function Layout() {

  return (
    <Container maxW='full'>
      <Header />
      <Divider />
      <Outlet />
      <Divider mt={20} />
      <Footer />
    </Container>
  )
}