import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import artistic from "../../../assets/artistic.jpeg";

export default function Artistic() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "sm", lg: "sm" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <Heading
          fontSize={headingSize}
          variant='anais'
        >
          Artistique
        </Heading>
        <Image
          src={artistic}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>
          Apprendre les règles comme un professionnel afin de les briser comme
          un artiste.
        </Text>
        <Text fontSize={textSize}>
          Le maquillage artistique permet de laisser parler l'imagination, la
          créativité et la passion. Du face-painting au body-painting selon vos
          projets, je crée les maquillages de la recherche à la face chart /
          body chart pour un résultat époustouflant sur le corps.
        </Text>

        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text whiteSpace="pre-line" h={{ md: 16, lg: 16 }}>
                    {cardItem.text}
                  </Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Face-Painting",
    text: "Beauté artistique, inspiration d'une matière, d'un animal, d'un sujet allégorique.",
  },
  {
    header: "Body-Painting",
    text: "Le corps devient une véritable toile.\nTout en respectant les courbes naturelles du corps ou non.",
  },
];
