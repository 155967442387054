import {
  Center,
  Heading,
  ScaleFade,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Card,
  Image,
  CardBody,
  Box,
  useColorModeValue,
  Skeleton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { BookCardInfo } from "../../interfaces/BookCardInfo";
import {
  getAllBookCardInfo,
  listAllFolders,
} from "../../service/firebaseService";
import { TabTitle } from "../../interfaces/TabTitle";
import ScrollToTop from "../ScrollToTop";

export default function Book() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [tabTitles, setTabTitles] = useState<TabTitle[]>([]);
  const [cardsByCategory, setCardsByCategory] = useState<
    Record<string, BookCardInfo[]>
  >({});

  function formatCategoryName(category: string): string {
    let result = category.trim();
    return result.charAt(0).toUpperCase() + category.slice(1);
  }

  async function fetchCardDataForCategory(
    category: string
  ): Promise<BookCardInfo[]> {
    return getAllBookCardInfo(category);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedCategories = await listAllFolders();
        setCategories(fetchedCategories);

        const formattedCategories = fetchedCategories.map(formatCategoryName);
        setTabTitles(
          formattedCategories.map((category) => ({ header: category }))
        );

        const cardDataPromises = fetchedCategories.map(
          fetchCardDataForCategory
        );
        const cardsData = await Promise.all(cardDataPromises);

        const newCardsByCategory: Record<string, BookCardInfo[]> = {};
        fetchedCategories.forEach((category, index) => {
          newCardsByCategory[category] = cardsData[index];
        });

        setCardsByCategory(newCardsByCategory);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <Center>
      <VStack>
        <Heading fontSize={headingSize} variant='anais'>
          Book
        </Heading>
        <Tabs colorScheme="purple" size="lg" align="center">
          <TabList>
            <SimpleGrid columns={{ base: 3, md: tabTitles.length }}>
              {tabTitles.map((tabTitle) => (
                <Tab key={tabTitle.header}>{tabTitle.header}</Tab>
              ))}
            </SimpleGrid>
          </TabList>

          <TabPanels>
            {loading ? (
              <TabPanel>
                <SimpleGrid columns={3} spacing={6}>
                  {[...Array(9)].map((_, index) => (
                    <Skeleton
                      key={`${Math.floor(index / 3)}-${index % 3}`}
                      height="500px"
                      width="400px"
                    />
                  ))}
                </SimpleGrid>
              </TabPanel>
            ) : (
              categories.map((category) => (
                <TabPanel key={category}>
                  <BookCards cards={cardsByCategory[category] || []} />
                  <ScrollToTop />
                </TabPanel>
              ))
            )}
          </TabPanels>
        </Tabs>
      </VStack>
    </Center>
  );
}

const BookCards = ({ cards }: { cards: BookCardInfo[] }) => {
  return (
    <Box
      w="100%"
      mx="auto"
      bg={useColorModeValue("white", "gray.800")}
      sx={{ columnCount: [1, 2, 3], columnGap: [1, 2, 3, 4] }}
    >
      {cards.map((bookCard: BookCardInfo) => (
        <ScaleFade key={bookCard.url} initialScale={0.5} in={true}>
          <Card mb={[1, 2, 3, 4]}>
            <CardBody p={0}>
              <Image
                boxShadow="dark-lg"
                src={bookCard.url}
                alt={bookCard.name}
                borderRadius="md"
              />
            </CardBody>
          </Card>
        </ScaleFade>
      ))}
    </Box>
  );
};
