import {
  Center,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Divider,
  ScaleFade,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import mode from "../../../assets/mode.jpeg";

export default function Mode() {
  const headingSize = useBreakpointValue({ base: "4xl", md: "5xl", lg: "5xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "xl", lg: "2xl" });
  const imageWidth = useBreakpointValue({ base: "sm", md: "sm", lg: "sm" });
  return (
    <Center>
      <VStack maxW="800px" spacing={[5, 5, 10]}>
        <Heading
          fontSize={headingSize}
          variant='anais'
        >
          Mode
        </Heading>
        <Image
          src={mode}
          boxShadow="dark-lg"
          borderRadius="md"
          w={imageWidth}
        />
        <Text fontSize={textSize}>
          Quel beau milieu qu'est la mode, au milieu des strass et des
          paillettes, je réaliserai un maquillage en adéquation avec le
          moodboard.
        </Text>
        <SimpleGrid
          spacing={4}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(3, 1fr)",
          ]}
        >
          {CARD_ITEMS.map((cardItem) => (
            <ScaleFade key={cardItem.header} initialScale={0.5} in={true}>
              <Card boxShadow="2xl">
                <CardHeader textAlign="center">
                  <Heading fontSize={16} variant='anais'>{cardItem.header}</Heading>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Text h={{ md: 20, lg: 20 }}>{cardItem.text}</Text>
                </CardBody>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </VStack>
    </Center>
  );
}

interface CardItem {
  header: string;
  text: string;
}

const CARD_ITEMS: Array<CardItem> = [
  {
    header: "Défilé",
    text: "Création de maquillages professionnels conçus pour les défilés.",
  },
  {
    header: "Magazine",
    text: "Réalisation de maquillages professionnels pour tous types de publications.",
  },
  {
    header: "Shooting photo",
    text: "Maquillages professionnels adaptés à la photographie.",
  },
];
