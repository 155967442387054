import * as React from "react"
import {
  ChakraProvider,
} from "@chakra-ui/react"

import theme from "./theme/theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "./components/layout"
import ErrorPage from "./components/body/ErrorPage";
import Home from "./components/body/Home";
import About from "./components/body/About";

import Wedding from "./components/body/individuals/Wedding";
import CustomMakeup from "./components/body/individuals/CustomMakeup";
import MakeupCourses from "./components/body/individuals/MakeupCourses";
import ChildrenMakeup from "./components/body/individuals/ChildrenMakeup";

import Mode from "./components/body/professionals/Mode";
import Events from "./components/body/professionals/Events";
import AudioVisual from "./components/body/professionals/AudioVisual";
import Artistic from "./components/body/professionals/Artistic";

import Book from "./components/body/Book";

import Contact from "./components/body/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: '/', element: <Home />
      },
      {
        path: '/about', element: <About />
      },

      {
        path: '/wedding', element: <Wedding />
      },
      {
        path: '/custom-makeup', element: <CustomMakeup />
      },
      {
        path: '/makeup-courses', element: <MakeupCourses />
      },
      {
        path: '/children-makeup', element: <ChildrenMakeup />
      },

      {
        path: '/mode', element: <Mode />
      },
      {
        path: '/events', element: <Events />
      },
      {
        path: '/audio-visual', element: <AudioVisual />
      },
      {
        path: '/artistic', element: <Artistic />
      },

      {
        path: '/book', element: <Book />
      },

      {
        path: '/contact', element: <Contact />
      },
      {
        path: "*", element: <ErrorPage />
      },

    ]
  },
]);


export const App = () => (
  <ChakraProvider theme={theme}>
    <RouterProvider router={router} />
  </ChakraProvider>
)
